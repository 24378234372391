import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { Icons } from '@components/Icon';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import TitleTemplate from '@ui/SectionTitle/Title';
import { publicAssets } from '@utils/url.utils';
import { ROUTES } from '../../constants';
import ContentTemplate from '../../ui/Content/Content';
import { Background, Benefit, BenefitIcon, BenefitLabel, Benefits, Image, Section } from './Subscription.elements';

export interface SubscriptionProps {}

const BENEFIT_ITEM_KEYS = [
    'homepage.subscription.benefit1',
    'homepage.subscription.benefit2',
    'homepage.subscription.benefit3',
    'homepage.subscription.benefit4',
    'homepage.subscription.benefit5',
];

const SubscriptionTemplate: FC<SubscriptionProps> = () => {
    const { t } = useTranslations();

    const router = useRouter();

    return (
        <Background>
            <ContentTemplate withPadding>
                <Section>
                    <Image src={publicAssets('/rocne_profi.png')} />
                    <Benefits>
                        <TitleTemplate alignLeft isBlack>
                            {t('homepage.subscription.title')}
                        </TitleTemplate>
                        {BENEFIT_ITEM_KEYS.map((benefit, key) => (
                            <Benefit key={key}>
                                <BenefitIcon name={Icons.CheckThin} />
                                <BenefitLabel dangerouslySetInnerHTML={{ __html: t(benefit) }}></BenefitLabel>
                            </Benefit>
                        ))}
                        <ButtonTemplate
                            onClick={() => router.push(ROUTES.SUBSCRIPTION)}
                            marginTop={40}
                            text={t('homepage.subscription.button')}
                        />
                    </Benefits>
                </Section>
            </ContentTemplate>
        </Background>
    );
};

export default SubscriptionTemplate;
