import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Script from 'next/script';
import EventTemplate from '@components/Event/Event';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import Title, { TitleTagTypes } from '@ui/SectionTitle/Title';
import SpinnerTemplate from '@ui/Spinner/Spinner';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_KEY, getAdsUrl, getImageBaseURL } from '../../constants';
import { BannerTypes, ITrainingItem } from '../../interfaces/Calendar';
import { calendarApi } from '../../services/calendar.service';
import { RootState } from '../../store';
import { setIsNeedToUpdateFavoriteList } from '../../store/ui';
import { ExternalBanner, InternalBanner, ShowMore, Wrapper } from './Calendar.elements';

export interface CalendarProps {
    events: ITrainingItem[];
    count: number;
}

const COUNT_OF_EVENTS_ON_PAGE = 6;

const INTERNAL_BANNER_POSITION = 3;
const EXTERNAL_BANNER_POSITION = 4;

const CalendarTemplate: FC<CalendarProps> = ({ events: data, count }) => {
    const { t } = useTranslations();

    const dispatch = useDispatch();

    const [events, setEvents] = useState(data);
    const [totalCount, setTotalCount] = useState(count);
    const [isLoading, setIsLoading] = useState(false);
    const [banners, setBanners] = useState([]);
    const [bannerVersion, setBannerVersion] = useState(BannerTypes.DESKTOP);
    const [externalBannerScript, setExternalBannerScript] = useState(null);

    const isLoggedIn = useSelector((state: RootState) => state.ui.isLoggedIn);

    const needToUpdateFavoriteList = useSelector((state: RootState) => state.ui.needToUpdateFavoriteList);

    const showMore = async () => {
        setIsLoading(true);

        const { data } = await calendarApi.getUpComingEvents(
            new Date().toString(),
            Cookies.get(ACCESS_TOKEN_KEY),
            COUNT_OF_EVENTS_ON_PAGE,
            events?.length
        );

        setEvents([...events, ...data?.results]);
        setTotalCount(data?.total_count);

        setIsLoading(false);
    };

    useEffect(() => {
        if (needToUpdateFavoriteList) {
            setIsLoading(true);

            calendarApi
                .getUpComingEvents(new Date().toString(), Cookies.get(ACCESS_TOKEN_KEY), events?.length)
                .then(({ data }) => {
                    setEvents(data?.results);
                    setTotalCount(data?.total_count);

                    setIsLoading(false);
                });

            dispatch(setIsNeedToUpdateFavoriteList(false));
        }
    }, [needToUpdateFavoriteList]);

    useEffect(() => {
        calendarApi.getHomepageBanners().then(({ data }) => setBanners(data?.results || []));

        fetch(`${getAdsUrl()}&zoneid=67`)
            .then((response) => response.text())
            .catch(() => {
                return null;
            })
            .then((data) => {
                setExternalBannerScript(
                    data
                        ?.replace(')', '')
                        ?.replace('document.write(', 'document.getElementById("homepageBanner").innerHTML = ')
                );
            });
    }, []);

    useEffect(() => {
        if (window.innerWidth >= 1180) setBannerVersion(BannerTypes.DESKTOP);
        if (window.innerWidth >= 768 && window.innerWidth < 1180) setBannerVersion(BannerTypes.TABLET);
        if (window.innerWidth < 768) setBannerVersion(BannerTypes.MOBILE);
    });

    return (
        <Wrapper>
            <Title tagType={TitleTagTypes.H2} isBlack>
                {t('homepage.calendar.title')}
            </Title>

            {events?.map((event: ITrainingItem, key) => {
                const index = Math.floor(Math.random() * banners.length);

                return (
                    <>
                        {key === INTERNAL_BANNER_POSITION && banners?.length > 0 && (
                            <a target="_blank" href={banners[index]?.redirect_url} rel="noreferrer">
                                <InternalBanner
                                    src={`${getImageBaseURL()}/media/image/${bannerVersion}_${
                                        banners[index]?._key
                                    }/file`}
                                />
                            </a>
                        )}
                        {key === EXTERNAL_BANNER_POSITION && externalBannerScript && !isLoggedIn && (
                            <ExternalBanner id="homepageBanner">
                                <Script>{externalBannerScript}</Script>
                            </ExternalBanner>
                        )}
                        <EventTemplate iKey={key} event={event} />
                    </>
                );
            })}
            {events?.length < totalCount && (
                <ShowMore>
                    {isLoading ? (
                        <SpinnerTemplate size={32} />
                    ) : (
                        <ButtonTemplate
                            marginTop={30}
                            whiteType
                            onClick={showMore}
                            text={t('homepage.calendar.button.more')}
                        />
                    )}
                </ShowMore>
            )}
        </Wrapper>
    );
};

export default CalendarTemplate;
