import React, { FC, useState } from 'react';
import { useRouter } from 'next/router';
import { Icons } from '@components/Icon';
import { IconTemplate } from '@components/Icon/IconTemplate';
import useScrollToSection from '@hooks/useScrollToSection';
import useTranslation from '@hooks/useTranslation';
import TitleTemplate, { TitleTagTypes } from '@ui/SectionTitle/Title';
import { publicAssets } from '@utils/url.utils';
import { ROUTES } from '../../constants';
import ContentWrapper from '../../ui/Content/Content';
import {
    About,
    AboutDescription,
    Image,
    Review,
    ReviewAuthor,
    ReviewText,
    Reviews,
    Section,
    Slider,
    SliderButton,
    Wrapper,
} from './Clients.elements';

interface ClientsProps {
    titleKey: string;
}

const REFERENCES = [
    {
        company: 'Univerzitná nemocnica Bratislava',
        name: 'PhDr. Tatiana Štihová',
        comment:
            'Sama som absolvovala už niekoľko rôznych seminárov, ktoré organizovali iné spoločnosti, avšak prvýkrát bol seminár na&nbsp;vysokej odbornej úrovni. Celý deň nás prostredníctvom názorných ukážok spracovaných v&nbsp;PowerPointe sprevádzala erudovaná lektorka pani Ing. Oxana Hospodárová. Na&nbsp;takmer všetky otázky našla odpovede a vedela poradiť aj praktickými riešeniami priamo z&nbsp;praxe a bežného života. Pohodu na&nbsp;seminári dotvorilo aj príjemné a elegantné prostredie hotela Saffron, milý a ochotný personál. Ďakujem organizátorom za&nbsp;príjemný zážitok. Odporúčam účasť na&nbsp;uvedenom seminári.',
    },
    {
        company: 'Brantner Nova, s.r.o',
        name: 'Anastázia Sedláková',
        comment:
            'Dobrý deň, dovoľte mi, prosím, poďakovať sa Vám, za možnosť po mnohých rokoch sa vrátiť na niekoľko dní do Prahy ako výherca v tombole na konferencii Daňový a odvodový kolotoč v Starom Smokovci – pobyt pre dvoch v hoteli Absolutum. Pobyt sme s manželom absolvovali v júni 2024 a veľmi sme si vychutnali toto krásne mesto. Ďakujeme Vám veľmi pekne.',
    },
    {
        company: 'Účtovníctvo Zuzana s. r. o.',
        name: 'Ing. Zuzana Blahová',
        comment:
            'Semináre Poradcu podnikateľa sú vynikajúce najmä pre&nbsp;profesionálnych účtovníkov. Témy a lektori seminárov sú výborne zvolení a na&nbsp;každom seminári som dostala viac informácií, ako som očakávala. Som veľmi spokojná.',
    },
    {
        company: 'WEBY GROUP, s.r.o.',
        name: 'Miriam Surová',
        comment:
            'Rada by som Vám touto cestou chcela poďakovať za&nbsp;zrealizovanie konferencie formou živého prenosu. Konferencie sme sa zúčastnili aj v&nbsp;minulom roku a dovolím si napísať vyjadrenie, že tento ročník mal prednášajúcich aj prednášky na&nbsp;vysokej odbornej, ale aj zrozumiteľnej úrovni. Moderovaná diskusia bola prínosom určite pre&nbsp;všetkých zúčastnených. Sami sme si z&nbsp;nej odniesli zodpovedané otázky. Tešíme sa na&nbsp;ďalší ročník. Verím, že sa ho zúčastníme osobne. Prajem Vám príjemný a úspešný deň.',
    },
    {
        company: 'BYTTERM, a.s. Žilina',
        name: 'Dana Pudíková',
        comment:
            'Na&nbsp;základe toho, že od&nbsp;Vás pravidelne dostávam ponuky a zároveň aj prehľad školení, chcela by som sa poďakovať za&nbsp;túto službu. Už niekoľkokrát som sa týchto školení zúčastnila a bola som milo prekvapená, že si stačilo vyznačiť školenie, o&nbsp;ktoré som mala záujem, a následne mi bola zaslaná faktúra, čím bolo potvrdené, že všetko ostatné je zabezpečené vždy pre&nbsp;moju spokojnosť a aj spokojnosť ostatných kolegýň.',
    },
    {
        company: 'FO',
        name: 'Dušan Peško',
        comment:
            'Absolvoval som niekoľko školení na&nbsp;tému GDPR, ale s&nbsp;vaším som bol najviac spokojný. Výklad lektorky JUDr. Macovej bol obsahovo kompaktný, zameraný na&nbsp;podstatné veci, vhodne doplnený praktickými radami a príkladmi z&nbsp;praxe. Váš kurz mal pre&nbsp;mňa najväčší úžitok.',
    },
    {
        company: 'Stredisko sociálnych služieb Petržalka',
        name: 'PhDr. Irena Tatranská',
        comment:
            'Z&nbsp;vydavateľského a vzdelávacieho domu Poradca podnikateľa odoberáme časopisy Dane a Účtovníctvo; Práca, mzdy a odmeňovanie a Finančného spravodajcu. Zúčastňujeme sa školení. S&nbsp;kvalitou časopisov sme spokojní, mesačníky informujú v&nbsp;priebehu roka o&nbsp;aktuálnych témach, venujú sa výkladu noviel zákonov, obsahujú prehľady – kalendár zákonných povinností na&nbsp;príslušný kalendárny mesiac, kurzy, vyhľadávač. Štruktúra časopisov je prehľadná, obsahuje veľa príkladov z&nbsp;praxe. Školenia sú lektorované profesionálne, vždy so študijným materiálom, prebiehajú v&nbsp;príjemnej atmosfére. Spolupráca s&nbsp;vydavateľstvom je operatívna.',
    },
    {
        company: 'Contineo, s. r. o.',
        name: 'Marcela Peterčáková',
        comment:
            'Pani JUDr. Macová bola neskutočná, podala toľko zaujímavých informácií, že ešte dnes, deň po&nbsp;školení, mám pocit, že som v&nbsp;aule... Chcela by som vysloviť veľké ĎAKUJEM a teším sa na&nbsp;budúce školenia. Prajem veľa profesionálnych úspechov.',
    },
    {
        company: 'KraussMaffei Technologies, spol. s r.o.',
        name: 'Jana Dologová',
        comment:
            'Službu Ročné Profivzdelávanie využívame v&nbsp;našej spoločnosti už takmer rok a bez&nbsp;váhania sme sa rozhodli predplatiť si ju aj na&nbsp;ten nasledujúci. Ponuka webinárov je naozaj veľmi zaujímavá, oslovila kolegov z&nbsp;rôznych oddelení, napr. HR, finančné, priemyselné inžinierstvo. Pravidelne dostávam e-mailovú notifikáciu o&nbsp;konaní webinárov, ktorú následne prepošlem podľa záujmu konkrétnym zamestnancom. Vzhľadom na&nbsp;pracovnú vyťaženosť veľmi oceňujeme možnosť pozrieť si webinár zo&nbsp;záznamu.',
    },
];

const ClientsTemplate: FC<ClientsProps> = ({ titleKey }) => {
    const { t } = useTranslation();

    const router = useRouter();

    const [visibleKey, setVisibleKey] = useState(0);

    useScrollToSection('referencie', 'referencesId');

    return (
        <>
            <Wrapper>
                <ContentWrapper mobileSidePadding={20} id="referencesId">
                    <TitleTemplate alignLeft tagType={TitleTagTypes.H3} isBlack>
                        {t(titleKey)}
                    </TitleTemplate>
                    <Section>
                        <Image src={publicAssets('/clients.webp')} />
                        <Reviews>
                            {REFERENCES.map((reference, key) => (
                                <Review className={key === visibleKey ? 'isVisible' : 'isHidden'} key={key}>
                                    <ReviewText dangerouslySetInnerHTML={{ __html: reference?.comment }} />
                                    <ReviewAuthor>
                                        {reference?.name} <br /> {reference?.company}
                                    </ReviewAuthor>
                                </Review>
                            ))}
                        </Reviews>
                    </Section>
                </ContentWrapper>
            </Wrapper>
            <ContentWrapper mobileSidePadding={20}>
                <Slider>
                    <SliderButton>
                        <IconTemplate
                            name={visibleKey > 0 ? Icons.ArrowCircleLeftActive : Icons.ArrowCircleLeftDisabled}
                            onClick={() => setVisibleKey(visibleKey > 0 ? visibleKey - 1 : 0)}
                        />
                    </SliderButton>
                    <SliderButton>
                        <IconTemplate
                            name={
                                visibleKey < REFERENCES.length - 1
                                    ? Icons.ArrowCircleRightActive
                                    : Icons.ArrowCircleRightDisabled
                            }
                            onClick={() =>
                                setVisibleKey(visibleKey < REFERENCES.length - 1 ? visibleKey + 1 : visibleKey)
                            }
                        />
                    </SliderButton>
                </Slider>
            </ContentWrapper>
            {router.pathname === ROUTES.HOME && (
                <ContentWrapper mobileSidePadding={20}>
                    <About>
                        <TitleTemplate isBlack alignCenter>
                            {t('clients.label')}
                        </TitleTemplate>
                        <AboutDescription>{t('clients.description')}</AboutDescription>
                    </About>
                </ContentWrapper>
            )}
        </>
    );
};
export default ClientsTemplate;
