import Link from 'next/link';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div`
    padding: ${rem(10)} 0;

    @media (min-width: ${breakpoints.md}px) {
        padding: ${rem(40)} 0;
    }
`;

export const Items = styled.div`
    margin-top: ${rem(20)};

    @media (min-width: ${breakpoints.md}px) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

export const Item = styled(Link)`
    display: flex;
    flex-direction: column;
    margin-bottom: ${rem(25)};
    cursor: pointer;
    align-items: center;
    position: relative;
    gap: ${rem(15)};

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: row;
        align-items: flex-start;
        gap: ${rem(20)};
    }

    @media (min-width: ${breakpoints.md}px) {
        flex-direction: column;
    }
`;

export const ImageSection = styled.div`
    position: relative;
`;

export const Image = styled.img`
    width: ${rem(320)};
    height: auto;
    opacity: 0.8;
    border-radius: ${rem(12)};
`;

export const Topic = styled.div`
    width: ${rem(320)};
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.sm}px) {
        width: 100%;
    }

    @media (min-width: ${breakpoints.md}px) {
        width: ${rem(320)};
        height: 100%;
    }
`;

export const TopicTitle = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.h6};
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    color: ${({ theme }) => theme.colors.black};
    display: block;
    line-height: ${rem(27)};
    text-decoration: none;
    line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
`;

export const TopicTime = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.textBase};
    color: ${({ theme }) => theme.colors.secondaryGrey};
    padding: ${rem(10)} 0;
`;

export const TopSection = styled.div``;

export const Tags = styled.div`
    position: absolute;
    bottom: ${rem(15)};
    left: ${rem(15)};
    right: ${rem(15)};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${rem(8)};
`;

export const Tag = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.textSmall};
    color: ${({ theme }) => theme.colors.white};
    padding: ${rem(2)} ${rem(10)};
    border-radius: ${rem(12)};
    border: 1px solid ${({ theme }) => theme.colors.white};
`;

export const Packages = styled.div`
    display: flex;
    margin: ${rem(10)} 0 ${rem(5)} 0;
    align-items: center;
    gap: ${rem(4)};
    font-size: ${({ theme }) => theme.fontSizes.textBase};
`;

export const ShowMore = styled.div`
    display: flex;
    justify-content: center;
`;
