import React from 'react';
import { FC } from 'react';
import { WrapperDiv, WrapperH1, WrapperH2, WrapperH3 } from './Title.element';

export interface TitleProps {
    isBlack?: boolean;
    alignLeft?: boolean;
    alignCenter?: boolean;
    withoutPadding?: boolean;
    tagType?: TitleTagTypes;
}

export enum TitleTagTypes {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
}

const TitleTemplate: FC<TitleProps> = ({ children, alignLeft, alignCenter, withoutPadding, isBlack, tagType }) => {
    if (tagType === TitleTagTypes.H1) {
        return (
            <WrapperH1
                $withoutPadding={withoutPadding}
                $alignLeft={alignLeft}
                $alignCenter={alignCenter}
                $isBlack={isBlack}
            >
                {children}
            </WrapperH1>
        );
    }

    if (tagType === TitleTagTypes.H3) {
        return (
            <WrapperH3
                $withoutPadding={withoutPadding}
                $alignLeft={alignLeft}
                $alignCenter={alignCenter}
                $isBlack={isBlack}
            >
                {children}
            </WrapperH3>
        );
    }

    if (tagType === TitleTagTypes.H2) {
        return (
            <WrapperH2
                $withoutPadding={withoutPadding}
                $alignLeft={alignLeft}
                $alignCenter={alignCenter}
                $isBlack={isBlack}
            >
                {children}
            </WrapperH2>
        );
    }

    return (
        <WrapperDiv $withoutPadding={withoutPadding} $alignLeft={alignLeft} $alignCenter={alignCenter} $isBlack={isBlack}>
            {children}
        </WrapperDiv>
    );
};

export default TitleTemplate;
