import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div`
    padding-top: ${rem(20)};
    background: rgba(116, 120, 129, 0.08);
    position: relative;

    @media (min-width: ${breakpoints.sm}px) {
        padding-top: ${rem(80)};
    }
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${rem(20)} 0 ${rem(20)} 0;

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: row;
    }

    @media (min-width: ${breakpoints.md}px) {
        align-items: center;
    }
`;

export const Reviews = styled.div``;

export const Review = styled.div`
    &.isVisible {
        display: block;
    }

    &.isHidden {
        display: none;
    }
`;

export const ReviewText = styled.p`
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.h6};
    line-height: ${rem(30)};
    color: ${({ theme }) => theme.colors.secondaryGrey};
`;

export const ReviewAuthor = styled.p`
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    color: ${({ theme }) => theme.colors.black};
    line-height: ${rem(24)};
`;

export const Image = styled.img`
    height: auto;
    border-radius: ${rem(12)};
    margin-bottom: ${rem(20)};
    margin-left: ${rem(-30)};

    @media (min-width: ${breakpoints.sm}px) {
        width: 55%;
        margin-right: 5%;
    }
`;

export const Slider = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.colors.white};
    padding: ${rem(30)} 0;
    display: flex;
    justify-content: center;

    @media (min-width: ${breakpoints.sm}px) {
        justify-content: flex-end;
        margin-top: ${rem(-110)};
        z-index: 999;
        position: relative;
        background: transparent;
    }
`;

export const SliderButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;

    svg {
        width: ${rem(40)};
        height: ${rem(40)};
    }
`;
export const About = styled.div`
    padding: ${rem(20)} 0 ${rem(100)} 0;
`;

export const AboutDescription = styled.p`
    font-size: ${({ theme }) => theme.fontSizes.h6};
    line-height: ${rem(30)};
    color: ${({ theme }) => theme.colors.grey};
    text-align: center;

    @media (min-width: ${breakpoints.sm}px) {
        width: 75%;
        margin: ${rem(-20)} auto 0 auto;
    }
`;
