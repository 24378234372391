import Image from 'next/image';
import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div`
    padding: ${rem(10)} 0 ${rem(80)} 0;
`;

export const Exhibitors = styled.div`
    padding: ${rem(20)} 0;
`;

export const Exhibitor = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin-bottom: ${rem(20)};
    cursor: pointer;

    @media (min-width: ${breakpoints.sm}px) {
        margin-left: ${rem(30)};
    }
`;

export const ExhibitorTitle = styled.div`
    padding: ${rem(20)} 0 ${rem(10)} 0;
    font-weight: ${({ theme }) => theme.fontWeights.textBold};
    text-align: center;
    width: ${rem(145)};

    @media (min-width: ${breakpoints.sm}px) {
        width: ${rem(170)};
    }
`;

export const ExhibitorAnnotation = styled.span`
    color: ${({ theme }) => theme.colors.grey};
    display: block;
    text-align: center;
    width: ${rem(145)};

    @media (min-width: ${breakpoints.sm}px) {
        width: ${rem(170)};
    }
`;

export const ExhibitorImage = styled(Image)`
    width: ${rem(145)};
    height: ${rem(210)};
    border-radius: ${rem(16)};
    object-fit: cover;

    @media (min-width: ${breakpoints.sm}px) {
        width: ${rem(170)};
        height: ${rem(240)};
    }
`;
