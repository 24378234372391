import React, { useState } from 'react';
import { FC } from 'react';
import useTranslations from '@hooks/useTranslation';
import ButtonTemplate from '@ui/Button/Button';
import LecturerTemplate from '@ui/Lecturer/Lecturer';
import PackageTemplate from '@ui/Package/Package';
import Package from '@ui/Package/Package';
import TitleTemplate from '@ui/SectionTitle/Title';
import SpinnerTemplate from '@ui/Spinner/Spinner';
import { formatLecturerName } from '@utils/person.utils';
import { parseHourAndMinutes } from '@utils/time.utils';
import { publicAssets } from '@utils/url.utils';
import { ROUTES, getImageBaseURL } from '../../constants';
import { SUBSCRIPTION_PACKAGES } from '../../constants/packages';
import { IRecordItem } from '../../interfaces/Calendar';
import { calendarApi } from '../../services/calendar.service';
import {
    Image,
    ImageSection,
    Item,
    Items,
    Packages,
    ShowMore,
    Tag,
    Tags,
    TopSection,
    Topic,
    TopicTime,
    TopicTitle,
    Wrapper,
} from './Archive.elements';

export interface ArchiveProps {
    videos: IRecordItem[];
    count: number;
}

const COUNT_OF_EVENTS_ON_PAGE = 3;

const ArchiveTemplate: FC<ArchiveProps> = ({ videos: data, count }) => {
    const { t } = useTranslations();

    const [videos, setVideos] = useState(data);
    const [totalCount, setTotalCount] = useState(count);
    const [isLoading, setIsLoading] = useState(false);

    const showMore = async () => {
        setIsLoading(true);

        const { data } = await calendarApi.getArchiveVideos(videos?.length + COUNT_OF_EVENTS_ON_PAGE);

        setVideos(data?.results);
        setTotalCount(data?.totalCount);

        setIsLoading(false);
    };

    return (
        <Wrapper>
            <TitleTemplate isBlack>{t('homepage.archive.title')}</TitleTemplate>
            <Items>
                {videos?.map((video, videoKey) => {
                    const frameKey = video?.video?.frame?.split('/')[0];
                    return (
                        <Item key={videoKey} href={`${ROUTES.TRAINING}/${video?.course?.slug}`}>
                            <ImageSection>
                                {video?.video?.frame && frameKey ? (
                                    <Image src={`${getImageBaseURL()}/media/video/${frameKey}/frame`} />
                                ) : (
                                    <Image src={publicAssets('/video_default.jpg')}></Image>
                                )}
                                <Tags>
                                    {video?.tags
                                        ?.filter((tag) => tag)
                                        ?.map((tag, key) => (
                                            <Tag key={key}>{tag?.title}</Tag>
                                        ))}
                                </Tags>
                            </ImageSection>
                            <Topic>
                                <TopSection>
                                    {video?.lectors?.map((person, personKey) => (
                                        <LecturerTemplate
                                            key={personKey}
                                            image={`${getImageBaseURL()}/media/people/${person?.photo}`}
                                            name={formatLecturerName(person)}
                                        />
                                    ))}
                                    <TopicTitle>{video?.course?.title}</TopicTitle>
                                    {video?.modules?.length > 0 && (
                                        <Packages>
                                            {t('homepage.archive.subscription')}:
                                            {video?.modules?.map((module, key) => {
                                                const subscriptionPackage = SUBSCRIPTION_PACKAGES.find(
                                                    (subPackage) => subPackage.key === module._key
                                                );

                                                return (
                                                    <div key={key}>
                                                        <PackageTemplate
                                                            color={subscriptionPackage.color}
                                                            symbol={subscriptionPackage.icon}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </Packages>
                                    )}
                                    <TopicTime>
                                        {t('homepage.archive.duration')}:{' '}
                                        {parseHourAndMinutes(Number(video.video.duration))}{' '}
                                    </TopicTime>
                                </TopSection>
                            </Topic>
                        </Item>
                    );
                })}
            </Items>
            {videos?.length < totalCount && (
                <ShowMore>
                    {isLoading ? (
                        <SpinnerTemplate size={32} />
                    ) : (
                        <ButtonTemplate onClick={showMore} whiteType text={t('homepage.calendar.button')} />
                    )}
                </ShowMore>
            )}
        </Wrapper>
    );
};

export default ArchiveTemplate;
