import IconTemplate from '@components/Icon';
import { rem } from 'polished';
import styled from 'styled-components';
import { getMaxPageWidth } from '../../constants';
import { breakpoints } from '../../styles/theme';

export const Background = styled.div`
    background: rgba(116, 120, 129, 0.08);
    margin: ${rem(90)} 0 ${rem(45)} 0;
`;

export const Section = styled.div<{ isStaticBanner?: boolean }>`
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpoints.sm}px) {
        flex-direction: ${(prop) => (prop.isStaticBanner ? 'column' : 'row')};
    }
`;

export const Image = styled.img<{ isStaticBanner?: boolean }>`
    width: 60%;
    margin: ${rem(40)} 10% ${rem(40)} 10%;

    @media (min-width: ${breakpoints.sm}px) {
        width: ${rem(250)};
        height: ${rem(260)};
        margin: ${rem(40)} ${rem(60)} ${rem(40)} ${rem(0)};
    }

    @media (min-width: ${breakpoints.md}px) {
        width: ${rem(450)};
        height: auto;
        margin: ${rem(20)} ${rem(90)} ${rem(20)} ${rem(20)};
    }
`;

export const Benefits = styled.div`
    padding: ${rem(20)};
`;

export const BenefitIcon = styled(IconTemplate)`
    svg {
        width: ${rem(25)};
        height: ${rem(25)};
        margin-right: ${rem(10)};
        margin-top: ${rem(-2)};

        g polyline {
            stroke: ${({ theme }) => theme.colors.black};
        }
    }
`;

export const Benefit = styled.span`
    display: flex;
    padding-bottom: ${rem(20)};
    font-size: ${({ theme }) => theme.fontSizes.h6};
    color: ${({ theme }) => theme.colors.secondaryGrey};

    strong {
        color: ${({ theme }) => theme.colors.black};
    }
`;

export const BenefitLabel = styled.span``;
