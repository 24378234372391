import { rem } from 'polished';
import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Wrapper = styled.div`
    padding: 0 0 ${rem(40)} 0;
    margin-top: ${rem(45)};
`;

export const InternalBanner = styled.img`
    width: 100%;
    margin-bottom: ${rem(15)};
`;

export const ExternalBanner = styled.div`
    margin-bottom: ${rem(15)};
    display: none;

    @media (min-width: ${breakpoints.xs}px) {
        display: block;
    }

    img {
        width: 100%;
    }
`;

export const ShowMore = styled.div`
    display: flex;
    justify-content: center;
`;
