import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../constants';
import { breakpoints } from '../styles/theme';

const useScrollToSection = (sectionNameInPathname: string, sectionIdInHtml: string, spacing?: number) => {
    const router = useRouter();

    useEffect(() => {
        if (router.asPath.includes(sectionNameInPathname)) {
            const element = document.getElementById(sectionIdInHtml);
            return window.scrollTo({
                top:
                    element.offsetTop -
                    (window.innerWidth > breakpoints.sm ? DESKTOP_HEADER_HEIGHT : MOBILE_HEADER_HEIGHT) - (spacing || 0),
                behavior: 'smooth',
            });
        }
    }, []);
};

export default useScrollToSection;
